<template>
  <ul class="classification-center">
    <li class="classification-item" v-for="(item,index) in classificationList" :key="index" @click="selectNav(item)">
      <h2 class="title" :class="$route.query.activityType === item.activityType ?'active_title' : ''">{{ item.activityTitle }}</h2>
      <div class="activitySlogan_style" v-if="item.activitySlogan">
        <span class="text"> {{ item.activitySlogan }}</span>
        <span class="activitySlogan_item"></span>
      </div>
    </li>
  </ul>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import {commonSessionStorage} from "@/utils/common";

export default {
  name: "activityTab",
  mixins: [Mixin],
  data() {
    return {}
  },
  computed: {
    classificationList() {
      let v = this;
      let list = [];
      let ongoingActivitiesData = commonSessionStorage.getItem('ongoingActivitiesData');
      if (ongoingActivitiesData) {
        let activitiesData = v.$store.state.ongoingActivitiesData || ongoingActivitiesData;
        if (activitiesData.length > 0) {
          list = activitiesData;
          if (!v.$store.state._isMultiSpecificationAttributeMerchant) {
            // 隐藏广告
            let hideActivityTitle = [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000069'), alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000942')];
            list = activitiesData.filter(i => {
              if (hideActivityTitle.includes(i.activityTitle)) {
                return i;
              }
            });
          }
        }
      }
      v.$forceUpdate();
      return list;
    }
  },
  methods: {
    // 选中商品菜单
    selectNav(item) {
      let v = this;
      if (v.system === 'distributor') {
        let talg = window.location.href.split('//');
        let ymsActivityPlanId = item.ymsActivityPlanId ? `&ymsActivityPlanId=${item.ymsActivityPlanId}` : ''
        let url = `/index.html#/shopList?activityType=${item.activityType}` + ymsActivityPlanId;
        if (talg[1].includes('localhost')) {
          let link = `${talg[0]}//${window.location.host}${url}`;
          window.open(link, '_blank');
        } else {
          let link = `/yms-shopping-service/${url}`;
          window.open(link, '_blank');
        }
      } else {
        v.$router.push({
          path: '/shopList',
          query: {
            activityType: item.activityType,
            ymsActivityPlanId: item.ymsActivityPlanId
          }
        });
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.classification-center {
  width: 100%;
  min-width: 644px;
  height: 40px;
  display: flex;
  align-items: center;

  .classification-item {
    display: flex;
    box-sizing: border-box;
    position: relative;

    .title {
      font-size: 15px;
      font-family: PingFang SC, serif;
      font-weight: 500;
      color: #000000;
      cursor: pointer;
      padding: 6px 20px;
      border-bottom: 2px solid transparent;
    }

    .active_title {
      color: @subject_color;
      border-bottom: 2px solid #FD5425;
    }

    .activitySlogan_style {
      padding: 0 4px;
      color: #fff;
      text-align: left;
      text-decoration: none;
      background-color: #FDA228;
      border-radius: 2px;
      white-space: nowrap;
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);

      .text {
        font-size: 12px;
        display: inline-block;
      }

      .activitySlogan_item {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        bottom: -4px;
        border-width: 4px 4px 0;
        border-top-color: #FDA228;
        left: 50%;
        margin-left: -4px;
      }
    }

    &:hover {
      .title {
        color: @subject_color;
      }
    }
  }
}
</style>