<template>
  <ul class="menu-box" v-if="showMenu && menuList.length >0 && showHierarchy">
    <div class="classification_box">
      <li class="primary_classification" v-for="(item,index) in menuList" :key="index">
        <h2 class="title_box" :title="item.name" @click="toListBtn(item)">
          {{ item.name }}
        </h2>
        <div class="secondary_classification" v-if="item.children && item.children.length >0">
        <span class="secondary_classification_item" v-for="(ele, ids) in item.children"
          :key="ids" @click="toListBtn(ele)">{{ ele.name }}
        </span>
          <div class="suspension_box" v-if="item.children && item.children.length >0">
            <template v-for="ele in item.children">
              <div class="suspension_box_item" v-if="ele.children && ele.children.length >0">
                <h2 class="suspension_box_title" @click="toListBtn(ele)">{{ ele.name }}</h2>
                <div class="three_classification">
                  <span class="three_classification_item" v-for="(talg, idx) in ele.children"
                    @click="toListBtn(talg)" :key="idx">{{ talg.name }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </li>
    </div>
  </ul>
</template>

<script>
export default {
  name: 'classification',
  props: {
    menuList: {  // 数据源
      type: Array,
      default: []
    },
    showMenu: {  // 是否默认展示所有分类的一级菜单
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showHierarchy: true
    };
  },
  methods: {
    toListBtn(data) {
      let v = this;
      v.showHierarchy = false;
      setTimeout(() => {
        v.showHierarchy = true;
      }, 2000);
      v.$emit('toListBtn', data);
    }
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.menu-box {
  width: 280px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  transition: all .5s ease;
  background-color: #fff;
  height: 460px;

  .classification_box {
    width: 100%;
    height: 460px;
    overflow: hidden;

    &:hover {
      overflow-y: auto;
      /*z-index: 1100;
      overflow-y: scroll;
      overflow-y: overlay;*/
    }
  }

  .primary_classification {
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    .title_box {
      color: #333;
      font-size: 15px;
      font-weight: bold;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      word-break: break-all;

      &:hover {
        cursor: pointer;
        color: @subject_color;
      }
    }

    .secondary_classification {
      display: flex;
      flex-wrap: wrap;
      width: 240px;

      .secondary_classification_item {
        color: #666;
        font-size: 14px;
        display: inline-block;
        padding: 10px 20px 10px 0;

        &:hover {
          cursor: pointer;
          font-weight: bold;
          color: @subject_color;
        }
      }
    }

    .suspension_box {
      display: none;
      width: 930px;
      height: 460px;
      position: absolute;
      background-color: #fff;
      top: 0;
      right: -920px;
      z-index: 1200;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
      padding: 0 20px 10px 20px;
      overflow-y: auto;
      align-items: center;

      .suspension_box_item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .suspension_box_title {
          color: #333;
          font-size: 15px;
          font-weight: bold;
          margin: 13px 15px 0 0;

          &:hover {
            cursor: pointer;
            font-weight: bold;
            color: @subject_color;
          }
        }

        .three_classification {
          display: flex;
          flex: 1;
          flex-wrap: wrap;

          .three_classification_item {
            color: #666;
            font-size: 15px;
            margin: 13px 15px 0 0;
            display: inline-block;

            &:hover {
              cursor: pointer;
              font-weight: bold;
              color: @subject_color;
            }
          }
        }
      }
    }

    &:hover {
      .suspension_box {
        display: block;
      }
    }
  }

  .primary_classification:last-child {
    border-bottom: none;
  }
}

</style>
